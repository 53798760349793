#file-manager {

    .list-view {

        tr {

            th, td {

            }
        }

        > thead {

            tr {

                th {

                    &:first-child {
                        border-bottom: none;
                        width: 106px;
                    }
                }
            }
        }

        > tbody {

            tr {
                cursor: pointer;
                outline: none;

                &.selected {
                    background: #E3F2FD;
                }

                td {
                    padding: 24px 8px;
                    border-bottom: none;

                    &.file-icon {
                        padding: 24px;
                        text-align: center;
                    }

                    &.name {
                        font-weight: 500;
                    }

                    &.type {
                        text-transform: capitalize;
                    }

                    &.owner {
                        text-transform: capitalize;
                    }

                    &.last-modified {

                    }

                    &.show-details {
                        text-align: center;
                    }

                    &.more {
                        padding: 24px 16px;
                        text-align: center;
                    }
                }
            }
        }
    }
}