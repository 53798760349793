#mail {

    // Center
    .center {

        .header {
            padding: 24px 0;

            .sidenav-toggle {
                margin: 0;
                width: 56px;
                height: 56px;
                background: #FFFFFF;
                border-radius: 0;
                border-right: 1px solid rgba(0, 0, 0, 0.12);
            }

            .search {
                height: 56px;
                line-height: 56px;
                padding: 18px;
                background: #FFFFFF;

                .icon {
                    margin: 0;
                }

                input {
                    padding-left: 16px;
                    height: 56px;
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }

        .content-card {

            .toolbar {
                position: relative;
                padding: 8px 16px;

                &.mail-read-pane-toolbar {
                    display: none;
                }

                .page-info {
                    font-weight: 500;
                    margin: 0 8px;
                    white-space: nowrap;
                }
            }

            .content-wrapper {
                overflow: hidden;
                position: relative;

                .mail-tabs {

                    md-tabs-wrapper {
                        display: none;
                    }

                    md-tabs-content-wrapper {
                        top: 0;
                    }

                    .content {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        padding: 0;

                        &.mail-list-pane {
                            border-right: 1px solid rgba(0, 0, 0, 0.12);
                            background: #FAFAFA;
                        }

                        &.mail-read-pane {
                            padding: 24px;
                        }
                    }
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (min-width: $layout-breakpoint-md) {

    #mail {

        .center {

            .content-card {

                .content-wrapper {

                    .mail-tabs {

                        md-tabs-content-wrapper {

                            md-tab-content {
                                width: 50%;
                                opacity: 1;
                                animation: none !important;

                                * {
                                    visibility: visible;
                                    transition: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #mail {

        .center {

            .content-card {

                .content-wrapper {

                    .mail-tabs {

                        .content {
                            position: relative;
                        }
                    }
                }
            }
        }
    }
}