#profile {

    .photos-videos-tab {
        padding: 0;

        .photos-videos {
            padding: 8px;

            .period {

                .period-title {
                    margin-bottom: 24px;

                    .name {
                        font-size: 20px;
                    }

                    .info {
                        margin-left: 16px;
                        font-size: 15px;
                        color: rgba(0, 0, 0, 0.54);
                    }
                }

                .period-media {
                    margin-bottom: 16px;

                    .media {
                        margin: 0 16px 16px 0;
                        position: relative;

                        .preview {
                            width: 256px;
                            height: 256px;
                            display: block;
                        }

                        .title {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            z-index: 10;
                            padding: 0 16px;
                            height: 48px;
                            line-height: 48px;
                            background: rgba(0, 0, 0, 0.54);
                            color: #fff;
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}