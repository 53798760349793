#theme-colors {

    .content {
        max-width: none !important;

        .color-tab {
            background: none;

            .color {
                overflow: hidden;
                margin-bottom: 24px;
                border: 1px solid rgba(0, 0, 0, 0.12);
            }
        }
    }
}